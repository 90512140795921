import logo from './logo.png';
import { useState, useEffect } from 'react';
import loading from './loading.gif';
import ok from './ok.gif';
import axios from 'axios';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Helmet } from "react-helmet";
import Select from 'react-select';

  function App() {

  const [schools, setSchools] = useState([]);
  const [bookedSeats, setBookedSeats] = useState(0);
  const capacity = 200;

  const getBookedSeats = () => {

    axios.get('https://api.manipuredu.online/api/getRegistrationCount')
    .then(response => {

      if (response.status == 200) {
        setBookedSeats(response.data);
      }

    })
    .catch(error => {

      console.log(error);

    });

  }
  
  useEffect(() => {

    axios.get('https://api.manipuredu.online/api/getManipurSchools')
      .then(response => {

        if (response.status == 200) {
          setSchools(response.data);
        }

      })
      .catch(error => {

        console.log(error);

      });

      getBookedSeats();

  }, [bookedSeats]);

  const [name, setName] = useState('');
  const [mobile1, setMobile1] = useState('');
  const [mobile2, setMobile2] = useState('');
  const [school, setSchool] = useState('');
  const [_class, setClass] = useState('');
  const [submit, setSubmit] = useState(false);

  const resetRegistration = () => {
    setName('');
    setMobile1('');
    setMobile2('');
    setSchool('');
    setClass('');
    setSubmit('');
  }

  const [registrationId, setRegistrationId] = useState('');

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const handleSubmit = () => {

    if (
      name == "" ||
      mobile1 == "" ||
      school == "" ||
      _class == ""
    ) {
      if (name == "") {
        alert("Please enter Your Name");
      }
      if (mobile1 == "") {
        alert("Please enter Your Primary Mobile");
      }
      if (school == "") {
        alert("Please enter Your School Name");
      }
      if (_class == "") {
        alert("Please enter Your Class");
      }
    } else {

      setSubmit(true);

      axios.post('https://api.manipuredu.online/api/submitProgrammeRegistration', {
        name,
        mobile1,
        mobile2,
        school,
        _class
      })
        .then(response => {

          if(response.data == "SEATS FULL"){
            alert('All Seats have been booked');            
          } else {
            setOpen(true);
            setRegistrationId(response.data);
          }

          setSubmit(false);
          getBookedSeats();
          resetRegistration();
        })
        .catch(error => {
          console.log(error);
          setSubmit(false);
          handleClickOpen();
        });
    }
  }

  return (
    <div
      style={{
        padding: '20px',
        background: 'rgb(2,0,36)',
        background: 'linear-gradient(324deg, rgba(2,0,36,1) 0%, rgba(150,150,164,1) 0%, rgba(173,227,238,0.2208070728291317) 100%)',
        height: '850px'
      }}>
      
      <Helmet>
        <meta charSet="utf-8" />
        <title>Programme Registration | SS Career Solution</title>
        <link rel="icon" href="/favicon.ico" />
        <link rel="canonical" href="https://programme.manipuredu.online" />
        <meta property="og:title" content="Programmes Registration | SS Career Solultion" />
        <meta property="og:description" content="Registration for Upcomming Career Counselling Programmes in Manipur, brought by SS Career Solution, 2nd Floor, Gypsy Building, Paona Bazar, Imphal West - MANIPUR 795001" />
        <meta property="og:image" content="/logo.png" />
      </Helmet>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '100%',
          margin: '0 auto'
        }}
      >
        {/* <div
          style={{
            display: 'grid',
            gridTemplateColumns: '50% 50%'
          }}
        >
          <span
            style={{
              justifySelf: 'start',
              alignSelf: 'start'
            }}
          >
            Seat Capacity: {capacity}
          </span>
          <span
            style={{
              justifySelf: 'end',
              alignSelf: 'end'
            }}
          >
            Free Seats: {parseInt(capacity) - parseInt(bookedSeats)}
          </span>
        </div> */}
        <div
          style={{
            textAlign: 'center'
          }}
        >
          <img
            src={logo}
            width="100px"
          />
        </div>
        <h1
          style={{
            textAlign: 'center',
            padding: '0',
            margin: '0'
          }}
        >
          <span
          >
            SS CAREER SOLUTION
          </span></h1>
        <h2
          style={{
            textAlign: 'center',
            padding: '0',
            marginTop: '10px'
          }}
        >
          <span
            style={{
              background: '#1976D2',
              paddingTop: '10px',
              paddingBottom: '10px',
              paddingLeft: '15px',
              paddingRight: '15px',
              borderRadius: '40px',
              fontSize: '20px',
              color: 'white'
            }}
          >
            PROGRAMME REGISTRATION
          </span></h2>
        <div
          id="admissionEnquiryInputMainDiv"
        >
          <div
            className="admissionEnquiryInputDiv"
          >
            <span
              className='admissionEnquiryLabel'
            >Name</span>
            <input
              className="admissionEnquiryInput"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div
            className="admissionEnquiryInputDiv"
          >
            <span
              className='admissionEnquiryLabel'
            >Primary Mobile</span>
            <input
              className="admissionEnquiryInput"
              type="text"
              value={mobile1}
              onChange={(e) => setMobile1(e.target.value)}
              maxLength="10"
              minLength="10"
            />
          </div>
          <div
            className="admissionEnquiryInputDiv"
          >
            <span
              className='admissionEnquiryLabel'
            >Secondary Mobile</span>
            <input
              className="admissionEnquiryInput"
              type="text"
              value={mobile2}
              onChange={(e) => setMobile2(e.target.value)}
              maxLength="10"
              minLength="10"
            />
          </div>
          <div
            className="admissionEnquiryInputDiv"
            style={{
            }}
          >
            <span
              className='admissionEnquiryLabel'
            >School</span>
            {
              schools.length > 0 ?
                <Select
                    placeholder={'Select School'}
                    options={schools}
                    value={school}
                    onChange={setSchool}
                />
                :
                <img
                  src={loading}
                  width="50px"
                />
            }         
          </div>
          <div
            className="admissionEnquiryInputDiv"
          >
            <span
              className='admissionEnquiryLabel'
            >Class</span>
            <select
              className="admissionEnquiryInput"
              style={{
                height: '45px',
                background: 'white',
                color: 'grey',
                textAlign: 'center',
                fontSize: '16px'
              }}
              type="text"
              value={_class}
              onChange={(e) => setClass(e.target.value)}
            >
              <option>Select Class</option>
              <option value="3">XI</option>
              <option value="4">XII</option>
              <option value="6">Graduation</option>              
            </select>
          </div>
          <div
            style={{
              textAlign: 'center',
              marginTop: '30px'
            }}
          >
            {
              submit == true ?
                <img
                  src={loading}
                  width="40px"
                />
                :
                <button
                  id="admissionEnquiryButton"
                  onClick={handleSubmit}
                >SUBMIT</button>

            }
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{fontSize: '16px'}}>
          Programme Registration Status
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"
            style={{
              textAlign: 'center',
              color: 'green'
            }}
          >
            <img
              src={ok}
              width="40px"
            />
            <p style={{
              fontWeight: 'bold'
            }}>Success!</p>
            <a target="_blank" href={'https://api.manipuredu.online/new_programmes/'+registrationId+'.pdf'} style={{ fontWeight: 'bold', color: '#1976D2' }}>Click to Download Entry Pass</a>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ textAlign: 'center' }}
        >
          <Button onClick={handleClose} style={{
            width: '100px',
            background: '#FFA500',
            color: 'white'
          }} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default App;
